<script lang="ts" setup>
	import type { IMuxVideo } from "~/server/models/mixins/muxVideo";

	const props = withDefaults(
		defineProps<{
			mux: IMuxVideo;
			autoplay?: boolean;
		}>(),
		{
			autoplay: true,
		}
	);

	const hls = computed(() => {
		return `https://stream.mux.com/${props.mux.playback_id}.m3u8`;
	});

	const poster = computed(() => {
		return `https://image.mux.com/${props.mux.playback_id}/thumbnail.jpg`;
	});

	const loaded = ref(false);

	function loadeddata() {
		loaded.value = true;
	}
</script>

<template>
	<HLSVideo :class="[{ loaded: loaded }]" @loadeddata="loadeddata" class="mux-player" :src="hls" :poster="poster" :muted="true" :autoplay="autoplay" :loop="true" />
</template>

<style scoped lang="scss">
	.mux-player {
		opacity: 0;
		transition: all 0.5s ease-out;
	}
	.mux-player.loaded {
		opacity: 1;
	}
</style>
