<script lang="ts" setup>
	import Hls from "hls.js";

	const props = withDefaults(
		defineProps<{
			muted: boolean;
			autoplay?: boolean;
			loop?: boolean;
			src: string;
			poster?: string;
			hls?: boolean;
		}>(),
		{
			autoplay: true,
			loop: true,
			hls: true,
		}
	);

	const video = ref<HTMLVideoElement | null>(null);

	const emit = defineEmits<{
		(e: "loadeddata"): void;
		(e: "play"): void;
		(e: "pause"): void;
	}>();

	let hls: Hls | null = null;

	onMounted(() => {
		watch(
			() => props.src,
			() => {
				destroy();
				build();
			},
			{ immediate: true }
		);
	});

	function build() {
		const reference = video.value;

		reference?.addEventListener("loadeddata", () => {
			emit("loadeddata");
		});

		reference?.addEventListener("error", (e) => {
			console.error(e);
		});

		reference?.addEventListener("play", (e) => {
			emit("play");
		});

		reference?.addEventListener("pause", () => {
			emit("pause");
		});

		if (reference && props.hls) {
			if (reference.canPlayType("application/vnd.apple.mpegurl")) {
				// nothing to do
			} else if (Hls.isSupported()) {
				// set up HLS
				var hls = new Hls();
				hls.loadSource(props.src);
				hls.attachMedia(reference);
			}
		}
	}

	function destroy() {
		if (hls) {
			hls.destroy();
			hls = null;
		}
	}

	onUnmounted(() => {
		destroy();
	});
</script>

<template>
	<video ref="video" class="hls-video" :src="props.src" :poster="poster" :loop="loop" :muted="muted" :autoplay="autoplay" playsinline></video>
</template>

<style scoped lang="scss">
	.hls-video {
		object-fit: cover;
		object-position: center center;
	}
</style>
